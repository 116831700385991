import axios from './axios_interceptors'
import config from '@/services/config'

export default {
  createLink(data) {
    return axios.post(`${config.urlCore}/api/v3/shorten-link/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  editLink(data, shorten_uuid) {
    return axios.put(`${config.urlCore}/api/v3/shorten-link/update/${shorten_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getShortLinks() {
    return axios.get(`${config.urlCore}/api/v3/shorten-link/list/?status=active`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getShortLinksArchived() {
    return axios.get(`${config.urlCore}/api/v3/shorten-link/list/?status=archived`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getShortLinksCreated(current_month = false) {
    const link = `${config.urlCore}/api/v3/shorten-link/total/links-created/${current_month ? '?current_month=true' : ''}`
    return axios.get(link,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteShortLinks(shorten_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/shorten-link/delete/${shorten_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  toggleStatusShortLinks(shorten_uuid) {
    return axios.get(`${config.urlCore}/api/v3/shorten-link/toggle-status/${shorten_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  detailShortLink(shorten_uuid) {
    return axios.get(`${config.urlCore}/api/v3/shorten-link/${shorten_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getMetricsShortLink(shorten_uuid, filters) {
    return axios.post(`${config.urlCore}/api/v3/shorten-link/metrics/${shorten_uuid}/`,
      filters,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
}