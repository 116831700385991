<template>
  <modal-brandme :show_modal="show">
    <template #title>{{Object.keys(link).length > 0 ? $t('tracking.editTracking') : $t('tracking.addTracking')}}</template>
    <template #body>
      <div>
        <strong>{{$t('tracking.trackingLinks')}}</strong>
        <p>{{$t('tracking.createLink')}}</p>
        <b-form @submit.prevent="toggleActionWS">
          <b-formGroup :label="$t('tracking.linkDestination')" label-for="link" :invalid-feedback="invalidFeedback" :state="state" class="mb-0 font-weight-bold">
            <b-form-input id="link" v-model="link_obj.link" class="input-link" autocomplete="off"></b-form-input>
          </b-formGroup>
          <b-collapse v-model="open_collapse" class="collapse-utm-paramaters row m-0">
            <b-formGroup label="UTM Source" label-for="link" :state="state" class="col-6 p-0">
              <b-form-input id="link" v-model="link_obj.utm_source" class="input-link" autocomplete="off"></b-form-input>
            </b-formGroup>
            <b-formGroup label="UTM Campaign" label-for="link"  :state="state" class="col-6 pr-0">
              <b-form-input id="link" v-model="link_obj.utm_campaign" class="input-link" autocomplete="off"></b-form-input>
            </b-formGroup>
            <b-formGroup label="UTM Medium" label-for="link" class="col-6 pl-0">
              <b-form-input id="link" v-model="link_obj.utm_medium" class="input-link" autocomplete="off"></b-form-input>
            </b-formGroup>
            <b-formGroup label="UTM Content" label-for="link" class="col-6 pr-0">
              <b-form-input id="link" v-model="link_obj.utm_content" class="input-link" autocomplete="off"></b-form-input>
            </b-formGroup>
          </b-collapse>
          <div class="button-configure-parameters" v-if="link_obj.link !== 'https://' && isUrl(link_obj.link)" @click="open_collapse = !open_collapse">
            <span>{{$t('tracking.configLink')}}</span>
            <feather-icon :icon="!open_collapse ? 'ChevronDownIcon' : 'ChevronUpIcon'" class="float-right"></feather-icon>
          </div>

          <div class="mt-2">
            <strong>{{$t('tracking.settingsLink')}}</strong>
            <b-formGroup :label="$t('tracking.linkName')" label-for="link_name">
              <b-form-input id="link_name" v-model="link_obj.name" class="input-link" autocomplete="off"></b-form-input>
            </b-formGroup>
          </div>
          <div class="mt-2 d-flex justify-content-end">
            <b-button variant="outline-secondary" @click="$emit('close_modal')">{{$t('tracking.cancel')}}</b-button>
            <b-button class="blue-button ml-1" variant="blue-button" type="submit" :disabled="!isUrl(link_obj.link)">{{Object.keys(link).length > 0 ? $t('tracking.editLink') : $t('tracking.addLink')}}</b-button>
          </div>
        </b-form>
      </div>
    </template>
  </modal-brandme>
</template>

<script>
import {
  BForm,
  BFormInput,
  BCollapse,
  BButton,
  BFormGroup,
} from 'bootstrap-vue';
import modalBrandme from '@/views/components/modal/ModalBrandme.vue';
import { isUrl } from '@/libs/utils/urls'
import tracking_service from '@/services/tracking_links';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'modalAddLink',
  components: {
    BForm,
    BFormInput,
    BCollapse,
    BFormGroup,
    BButton,
    modalBrandme,
  },
  props: {
    open_modal: {
      type: Boolean,
      required: true
    },
    link: {
      type: Object
    }
  },
  computed: {
    state() {
      return isUrl(this.link_obj.link);
    },
    invalidFeedback() {
      return this.$t('tracking.validUrl');
    }
  },
  data() {
    return {
      isUrl,
      show: this.open_modal,
      link_obj: {
        link: 'https://',
        name: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
      },
      open_collapse: false,
    }
  },
  created() {
    if (Object.keys(this.link).length > 0) {
      this.link_obj = {...this.link}
    }
  },
  methods: {
    logicAddUTM(value_utm, utm_name) {
      const url = new URL(this.link_obj.link);
      (value_utm.length > 0) ? url.searchParams.set(utm_name, value_utm) : url.searchParams.delete(utm_name, value_utm);
      this.link_obj.link = url.toString();    
    },
    addLink() {
      Object.keys(this.link_obj).forEach(key => {
        if (this.link_obj[key] === '') delete this.link_obj[key];
      });
      tracking_service.createLink(this.link_obj).then((response) => {
        if (response.status >= 400) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('tracking.noAdd'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: this.$t('tracking.urlNoComplete'),
              }
            })
          })
        } else {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('tracking.linkAdded'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('tracking.linkSuccess'),
              }
            })
          })
          this.show = false;
          this.$emit('add_data', response);
        }
      })
    },
    editLink() {
      Object.keys(this.link_obj).forEach(key => {
        if (this.link_obj[key] === '') delete this.link_obj[key];
      });
      tracking_service.editLink(this.link_obj, this.link_obj.uuid).then((response) => {
        console.log(response)
        if (response.status >= 400) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('tracking.noUpdate'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response.data.response.message.link[0],
              }
            })
          })
        } else {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('tracking.linkUpdate'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('tracking.updateSuccess'),
              }
            })
          })
          this.$emit('update_data', response);
        }
      });
    },
    toggleActionWS() {
      if (Object.keys(this.link).length > 0) this.editLink();
      else this.addLink();
    },
  },
  watch: {
    'link_obj.utm_source'(val) {
      this.logicAddUTM(val, 'utm_source')
    },
    'link_obj.utm_campaign'(val) {
      this.logicAddUTM(val, 'utm_campaign')
    },
    'link_obj.utm_medium'(val) {
      this.logicAddUTM(val, 'utm_medium')
    },
    'link_obj.utm_content'(val) {
      this.logicAddUTM(val, 'utm_content')
    }
  }
}
</script>

<style scoped>
.button-configure-parameters {
  background-color: #e6e6e6;
  padding: 0.5em;
  cursor: pointer;
}
.input-link {
  border-radius: 0;
}
.collapse-utm-paramaters {
  padding: 0.5em;
  border-right: 1px solid #d8d6de;
  border-left: 1px solid #d8d6de;
  font-weight: bold;
}
</style>